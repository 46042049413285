import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import { ImageCaption, Quote, Text, Embed } from "../components/Blog/slices"
// import CategoryTags from "../pages/blog/CategoryTags"
import "../styles/blog-post.css"
import { withPreview } from "gatsby-source-prismic"
import { formattedDate } from "../utils/helper"
import Seo from "../components/Seo"
import { Date } from "prismic-reactjs"
import Carousel from "react-multi-carousel"
import { Facebook, Twitter, Linkedin, Whatsapp } from "react-social-sharing"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
    // slidesToSlide: 1, // optional, default to 1.
  }
}

// Sort and display the different slice options
const PostSlices = ({ slices }) => {
  // console.log("slices", slices)
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case "text":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<Text slice={slice} />}
            </div>
          )

        case "video_link":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<Embed slice={slice} />}
            </div>
          )

        case "quote":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<Quote slice={slice} />}
            </div>
          )

        case "image":
          return (
            <div key={index} className="homepage-slice-wrapper">
              {<ImageCaption slice={slice} />}
            </div>
          )
        case "heading":
          return (
            <h3
              key={index}
              className="blog-inner-heading homepage-slice-wrapper"
            >
              {slice.primary.heading.text}
            </h3>
          )
        default:
          return
      }
    })()
    return res
  })
}

// Display the title, date, and content of the Post
const PostBody = ({ blogPost, location }) => {
  const blogMessage = `${blogPost.title.text}. Checkout our blogs at ratelock.ca`
  const blogLink = location.href
  return (
    <div className="post-body">
      <div className="container">
        {blogPost.video?.html ? (
          <div className="post-embed">
            {/* <div>{RichText.render(slice.primary.embed_link.raw)}</div> */}
            <div dangerouslySetInnerHTML={{ __html: blogPost.video.html }} />
          </div>
        ) : (
          blogPost &&
          blogPost.blog_image &&
          blogPost.blog_image.fluid?.src && (
            <div className="img-container">
              <img
                alt={blogPost.blog_image.alt}
                src={blogPost.blog_image.fluid.src}
              />
            </div>
          )
        )}
        <div className="blog-card">
          <div className="social-share">
            <Facebook solid small message={blogMessage} link={blogLink} />
            <Twitter solid small message={blogMessage} link={blogLink} />
            <Linkedin solid small message={blogMessage} link={blogLink} />
            <Whatsapp solid small message={blogMessage} link={blogLink} />
          </div>
          <p className="hint">
            {blogPost.published_date
              ? formattedDate(Date(blogPost.published_date))
              : ""}
          </p>
          <h1>{blogPost.title.text ? blogPost.title.text : "Untitled"}</h1>
          {/* <p className="short-description">
            {blogPost.short_text.text ? blogPost.short_text.text : ""}
          </p> */}
          {/* <p className="hint">
            <span>AUTHOR:</span>{" "}
            <span className="author">
              {blogPost.author ? blogPost.author : ""}
            </span>
          </p> */}
        </div>
      </div>
      <div className="post-content">
        {/* Go through the slices of the post and render the appropiate one */}
        <PostSlices slices={blogPost.body} />
      </div>
      {/* {blogPost.author && (
        <div className="author-block">
          <p>{blogPost.author}</p>
          {blogPost.author_image &&
            blogPost.author_image.fluid &&
            blogPost.author_image.fluid.src && (
              <div className="author-image img-container">
                <img
                  alt={blogPost.author_image.alt}
                  src={blogPost.author_image.fluid.src}
                />
              </div>
            )}
          {blogPost.author_description && blogPost.author_description.text && (
            <p>{blogPost.author_description.text}</p>
          )}
        </div>
      )} */}
    </div>
  )
}

const AdImage = ({ src, alt }) => (
  <div className="ad img-container">
    <img alt={alt} src={src} />
  </div>
)

const BlogPost = (props) => {
  // Define the Post content returned from Prismic

  // Data from preview
  const doc = props?.data?.prismicBlogPost
  let data
  if (doc) {
    data = doc.data
  }

  if (!doc || !data) return null
  return (
    <Layout location={props.location} page="blog-post">
      <Seo
        isBlogPost={true}
        title={`${data.title.text} | Blog | RateLock`}
        description={`${data.short_text.text ?? "Blog Post"}`}
        metaImage={data.blog_image.fluid?.src}
        location={props.location}
        schema={{
          "@context": "https://schema.org",
          "@type": "BlogPosting",
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": props.location.pathname
          },
          headline: data.title.text,
          image: data.blog_image.fluid?.src,
          author: {
            "@type": "Person",
            name: data.author
          },
          publisher: {
            "@type": "Organization",
            name: "Ratelock.ca",
            logo: {
              "@type": "ImageObject",
              url:
                "https://images.prismic.io/ratelockca/db486a8d-74fd-4cc5-9e5a-4763f80499f0_standalone_padded_large.jpg"
            }
          },
          datePublished: data.published_date,
          dateModified: data.published_date
        }}
      ></Seo>
      <section id="blog-post">
        <div className="back">
          <Link to="/blog">back to blogs</Link>
        </div>
        <div className="layout-container">
          <PostBody blogPost={data} location={props.location} />
          <div className="right-container">
            {data.ad_image_group_1 && data.ad_image_group_1.length > 0 ? (
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                // customTransition="all 1s"
                transitionDuration={1500}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                arrows={false}
                containerClass="carousel-container"
                partialVisible="false"
                // dotListClass="custom-dot-list-style"
                itemClass="carousel-item"
              >
                {data.ad_image_group_1.map(
                  (i, idx) =>
                    i &&
                    i.ad &&
                    i.ad.fluid?.src && (
                      <AdImage
                        key={idx}
                        src={i.ad.fluid.src}
                        alt={i.ad.alt ?? "Ratelock.ca"}
                      />
                    )
                )}
              </Carousel>
            ) : null}
            {data.ad_image_2 &&
              data.ad_image_2.fluid &&
              data.ad_image_2.fluid.src && (
                <AdImage
                  src={data.ad_image_2.fluid.src}
                  alt={data.ad_image_2.alt ?? "Ratelock.ca"}
                />
              )}
            {data.ad_image_3 &&
              data.ad_image_3.fluid &&
              data.ad_image_3.fluid.src && (
                <AdImage
                  src={data.ad_image_3.fluid.src}
                  alt={data.ad_image_3}
                />
              )}
            {data.author && (
              <div className="author-container">
                <div className="author-card">
                  {data.author_description && data.author_description.text && (
                    <div className="author-text">
                      <strong>Author: </strong>
                      {data.author_description.text}
                      <i className="fas fa-quote-right quote"></i>
                    </div>
                  )}
                  {data.author && (
                    <div className="author-footer">
                      {data.author_image?.url && (
                        <div className="author-image">
                          {data.author_image &&
                            data.author_image.fluid &&
                            data.author_image.fluid.src && (
                              <img
                                alt={data.author_image.alt}
                                src={data.author_image.fluid.src}
                              />
                            )}
                        </div>
                      )}
                      <h3 className="author-name">{data.author}</h3>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* <CategoryTags /> */}
            <Link to="/apply-now" className="btn">
              Apply Now
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const blogPostQuery = graphql`
  query BlogPost($uid: String) {
    prismicBlogPost(uid: { eq: $uid }) {
      uid
      type
      url
      data {
        published_date
        title {
          text
        }
        short_text {
          text
        }
        author
        author_image {
          alt
          url
          fluid(maxWidth: 1000, maxHeight: 400) {
            src
          }
        }
        author_description {
          text
        }
        blog_image {
          alt
          fluid(maxWidth: 1000, maxHeight: 400) {
            src
          }
        }
        video {
          html
        }
        ad_image_group_1 {
          ad {
            alt
            fluid(maxWidth: 1000, maxHeight: 400) {
              src
            }
          }
        }
        ad_image_2 {
          alt
          fluid(maxWidth: 1000, maxHeight: 400) {
            src
          }
        }
        ad_image_3 {
          alt
          fluid(maxWidth: 1000, maxHeight: 400) {
            src
          }
        }
        body {
          __typename
          ... on PrismicBlogPostBodyHeading {
            id
            slice_type
            slice_label
            primary {
              heading {
                html
                text
                raw
              }
            }
          }
          ... on PrismicBlogPostBodyText {
            id
            slice_type
            slice_label
            primary {
              paragraph {
                html
                text
                raw
              }
            }
          }
          ... on PrismicBlogPostBodyVideoLink {
            id
            slice_type
            slice_label
            primary {
              embed_link {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyQuote {
            id
            slice_type
            slice_label
            primary {
              quote {
                html
                text
                raw
              }
            }
          }
          ... on PrismicBlogPostBodyImage {
            id
            slice_type
            slice_label
            primary {
              caption {
                html
                text
                raw
              }
              image {
                alt
                copyright
                url
                thumbnails
              }
            }
          }
        }
      }
    }
  }
`
export default withPreview(BlogPost)
